import React, { useState, useEffect, useCallback } from "react"
import { Link } from "gatsby"
import {
  RiMenu3Line,
  RiFacebookBoxLine,
  RiInstagramLine,
  RiMailLine,
  RiGithubFill,
  RiInformationLine,
  RiAppsLine,
} from "react-icons/ri"

const OverlayMenu = () => {
  const [isOpen, setOpen] = useState(false)

  const onDocumentClick = useCallback(
    e => {
      if (e.target.id !== "clickable") {
        setOpen(false)
      }
    },
    [setOpen]
  )

  useEffect(() => {
    document.addEventListener("click", onDocumentClick)
    return () => document.removeEventListener("click", onDocumentClick)
  }, [onDocumentClick])

  const open = () => {
    setOpen(true)
  }

  const close = () => {
    setOpen(false)
  }
  return (
    <React.Fragment>
      <div
        role="link"
        tabIndex={0}
        className="fake-link"
        onClick={open}
        onKeyDown={open}
        id="clickable"
      >
        <RiMenu3Line id="clickable" style={{ pointerEvents: "none" }} />
      </div>
      {isOpen && (
        <div className="overlay-menu">
          <div>
            <ul>
              <li>
                <Link to="/" onClick={close}>
                  <RiAppsLine /> Projects
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={close}>
                  <RiInformationLine /> About me
                </Link>
              </li>
              <li>
                <a
                  href="mailto:elayabharath@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <RiMailLine /> Email
                </a>
              </li>
              <hr />
              <li>
                <a
                  href="https://www.facebook.com/elayabharath"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <RiFacebookBoxLine /> Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/elayabharath/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <RiInstagramLine /> Instagram
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/elayabharath"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <RiGithubFill /> Github
                </a>
              </li>
            </ul>
          </div>
          <div className="overlay-backdrop"></div>
        </div>
      )}
    </React.Fragment>
  )
}

export default OverlayMenu
