import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import {
  RiFacebookBoxLine,
  RiInstagramLine,
  RiMailLine,
  RiGithubFill,
} from "react-icons/ri"
import "bootstrap/dist/css/bootstrap.min.css"
import "./../styles/index.css"

import { Container, Row, Col } from "reactstrap"
import Typewriter from "typewriter-effect"
import SEO from "./seo"
import OverlayMenu from "./OverlayMenu"

const Layout = ({ title, children }) => {
  return (
    <>
      <SEO title={title} />
      <Header title={title} />
      <Container className="content">
        <main>{children}</main>
      </Container>
      <Footer />
    </>
  )
}

const showScroll = 240

const getWindow = () => {
  if (typeof window !== `undefined`) {
    return window
  } else {
    return { pageYOffset: 0 }
  }
}

export const Header = ({ title }) => {
  const [showTitle, setShowTitle] = useState(
    getWindow().pageYOffset > showScroll
  )

  const handleScroll = () => {
    getWindow().pageYOffset > showScroll
      ? setShowTitle(true)
      : setShowTitle(false)
  }

  useEffect(() => {
    getWindow().addEventListener("scroll", handleScroll)
  }, [])

  return (
    <header className={showTitle ? "shadow" : ""}>
      <Container>
        <div className="header-grid">
          <div className="header-grid-item">
            <Link to="/">
              <img
                src="/images/logo.svg"
                alt="logo"
                height={20}
                style={{ marginTop: -6 }}
              />
            </Link>
          </div>
          <div className="header-grid-item text-center header-title">
            {showTitle ? title : null}
          </div>
          <div className="header-grid-item text-right">
            <OverlayMenu />
          </div>
        </div>
      </Container>
    </header>
  )
}

export const Footer = () => (
  <footer id="contact">
    <Container>
      <Row>
        <Col>
          <h1>
            Let's work on something
            <br />
            <Typewriter
              options={{
                strings: [
                  "fun!",
                  "together!",
                  "great!",
                  "together!",
                  "awesome!",
                  "together!",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to="/about">About</Link>
          <a
            href="mailto:elayabharath@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <RiMailLine />
          </a>
          <a
            href="https://www.facebook.com/elayabharath"
            target="_blank"
            rel="noopener noreferrer"
          >
            <RiFacebookBoxLine />
          </a>
          <a
            href="https://www.instagram.com/elayabharath/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <RiInstagramLine />
          </a>
          <a
            href="https://github.com/elayabharath"
            target="_blank"
            rel="noopener noreferrer"
          >
            <RiGithubFill />
          </a>
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Layout
